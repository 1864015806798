<template>
  <div id="loading-spinner">
    <i class="fas fa-spinner fa-spin mr-3"></i>Loading...
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#loading-spinner {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000000e8;
  color: #ffffff;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  font-size: 1.5rem;
}
</style>