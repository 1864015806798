<template>
  <div class="main-content">
    <div class="content-with-sidebar">
      <div class="left-side-list shadow">
        <loading-spinner v-if="isLoading" />
        <div class="p-3 bg-black row mx-0">
          <div class="col px-1">
            <select class="custom-select" @change="selectCctv($event)">
              <option
                :selected="!$route.query || !$route.query.cctv"
                value="all"
              >
                All CCTV
              </option>
              <option
                v-for="cctv in cctvs"
                :key="`CCTV${cctv.id}`"
                :value="cctv.id"
                :selected="$route.query && $route.query.cctv == cctv.id"
              >
                {{ cctv.name }}
              </option>
            </select>
          </div>
          <div class="col px-1 clickable">
            <v-date-picker
              v-model="filter.date"
              class="v-calendar"
              :masks="{ input: 'YYYY-MM-DD' }"
              trim-weeks
              :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="form-control"
                  :value="inputValue"
                  v-on="inputEvents"
                  readonly
                />
                <i class="far fa-calendar-alt calendar-icon"></i>
              </template>
            </v-date-picker>
          </div>
        </div>
        <div class="detection-list-container" v-if="events.length > 0">
          <div
            class="detection-list list-item w-100 h-auto border-bottom d-flex p-3 align-items-center clickable"
            :class="{ active: event.id == currentEvent.id }"
            v-for="(event, index) in events"
            :key="`Event${event.id}`"
            @click="viewEvent(event)"
          >
            <div class="detection-image flex-grow-0 flex-shrink-0 shadow">
              <img
                :src="`${apiUrl}${event.image.url}`"
                alt=""
                v-if="event.image && event.image.url"
              />
              <img src="@/assets/image_placeholder.png" alt="" v-else />
            </div>
            <div
              class="px-3 d-flex justify-content-center flex-column flex-grow-1 w-100"
            >
              <p class="m-0 text-muted">#{{ index + 1 }}</p>
              <h5 class="m-0 font-weight-normal">Person</h5>
              <p class="m-0">
                <i class="fas fa-clock mr-2 text-primary"></i>
                {{ formatDate(event.created_at) }}
              </p>
              <p class="m-0">
                <i class="fas fa-video mr-2 text-primary"></i>
                {{ event.camera ? event.camera.name : "Undefined" }}
              </p>
            </div>
          </div>
          <div class="p-4 text-center text-muted font-italic">End of list</div>
        </div>
        <div class="p-4 text-center text-muted font-italic" v-else>
          No detection yet
        </div>
      </div>
      <div class="right-side-image">
        <img
          v-if="currentEvent && currentEvent.image"
          :src="`${apiUrl}${currentEvent.image.url}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import Moment from "moment";
import LoadingSpinner from "../components/LoadingSpinner";

export default {
  components: { LoadingSpinner },
  name: "Detections",
  props: ["latestDetection"],
  data() {
    return {
      cctvs: [],
      events: [],
      currentEvent: null,
      apiUrl: API.apiUrl(),
      isLoading: false,
      filter: {
        date: Moment().format("YYYY-MM-DD"),
      },
    };
  },
  watch: {
    "$route.query": {
      handler() {
        this.getCctvs();
      },
      deep: true,
      immediate: true,
    },
    latestDetection: {
      handler() {
        if (
          this.events.length > 0 &&
          this.latestDetection &&
          !this.isLoading &&
          Moment(this.latestDetection.created_at).format("YYYY-MM-DD") ==
            this.filter.date
        ) {
          if (
            !this.$route.query.cctv ||
            this.latestDetection.camera.id == this.$route.query.cctv
          ) {
            this.getEvents(this.latestDetection.id);
          }
        }
      },
      immediate: true,
    },
    "filter.date": {
      handler() {
        this.getEvents();
      },
    },
  },
  methods: {
    selectCctv(e) {
      if (e.target.value != "all") {
        this.$router.replace({
          query: {
            cctv: e.target.value,
          },
        });
      } else {
        this.$router.replace({
          query: {},
        });
      }
    },
    formatDate(date) {
      return Moment(date).format("DD/MM/YYYY h:mm A");
    },
    viewEvent(event) {
      this.currentEvent = event;
    },
    getCctvs() {
      this.isLoading = true;

      API.get("cameras?type_ne=aisc")
        .then((retVal) => {
          this.cctvs = retVal.data;

          this.getEvents();
        })
        .catch((err) => {
          console.error(err);

          this.isLoading = false;
        });
    },
    getEvents(id) {
      let endpoint = "events?";

      if (id) {
        endpoint = `events/${id}?`;
      }

      if (this.$route.query.cctv) {
        endpoint += `camera=${this.$route.query.cctv}&`;
      }

      let startDate, endDate;

      if (this.filter.date) {
        startDate = Math.floor(
          Moment(this.filter.date).startOf("day").valueOf() / 1000
        );
        endDate = Math.floor(
          Moment(this.filter.date).endOf("day").valueOf() / 1000
        );
      } else {
        startDate = Math.floor(Moment().startOf("day").valueOf() / 1000);
        endDate = Math.floor(Moment().endOf("day").valueOf() / 1000);
      }

      endpoint += `_sort=created_at:DESC&timestamp_gte=${startDate}&timestamp_lte=${endDate}`;

      API.get(`${endpoint}`)
        .then((retVal) => {
          if (id && retVal.data && !this.events.find((ev) => ev.id == id)) {
            this.events.unshift(retVal.data);
          } else {
            this.events = retVal.data;

            if (this.events.length > 0) {
              this.currentEvent = this.events[0];
            } else {
              this.currentEvent = null;
            }
          }

          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);

          this.isLoading = false;
        });
    },
  },
};
</script>
